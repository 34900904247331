/** @format */

export const ADDED_TO_CART_MESSAGE = "Added to cart";
export const CART_KEY_LOCAL_STORAGE = "cartProducts";
export const WISHLIST_REDIRECTION_LOCAL_STORAGE = "wishlist-redirection";
export const USER_INFORMATION_LOCAL_STORAGE = "userInformation";
export const PRODUCT_VERSION_LOCAL_STORAGE = "productVersion";
export const TOKEN_LOCAL_STORAGE = "token";
export const EMPTY_FIELD_MESSAGE = "Field cannot be empty.";
export const EMAIL_VALIDATION_MESSAGE = "Please enter a valid email address.";
export const NUMBER_VALIDATION_MESSAGE = "Please enter a valid number";
export const PASSWORD_VALIDATION_MESSAGE =
  "Password must contain one uppercase,one lowercase, number, special character and minimum 8 character ( Accepted Special Characters @#$%^&+=£*_# )";
export const CHANNEL_PLATFORM = "RetailWeb";
export const GOOGLE_ADDRESS_API_KEY = "AIzaSyDqRwktb0WF6d7KbMg-208CyZi1h99gSMg";
export const NO_GIFT_CARD_MESSAGE = "No Gift Cards Available !";
export const NO_WISHLIST_MESSAGE = "No Wishlists Available !";

export const NO_PRODUCTS_FOUND_MESSAGE =
  "Sorry, no products found with the current filters !";

export const COMMON_ERROR_MESSAGE = "Something Went Wrong !";
export const UNAUTORIZED_PAYMENT_MESSAGE =
  "You are required to login to make the payment.";
export const UNAUTHORIZED_WISHLIST_MESSAGE =
  "You are required to login to add in wishlist.";

export const MegaMenuLayoutTypes = {
  Vertical: "1",
  Horizontal: "2",
  VerticalCardBlock: "3",
  HorizontalCardBlock: "4",
  VerticalWithHorizontalCardBlock: "5",
};

export const ANIMATION_CLASS = "fade-up";

export const themeColorDetails = [
  {
    identifier: "8",
    variableName: "--alert-color-info",
    defaultValue: "#eff6fd",
  },
  {
    identifier: "9",
    variableName: "--alert-color-info-text",
    defaultValue: "#fff",
  },
  { identifier: "5", variableName: "--body-bg-color", defaultValue: "#fff" },
  {
    identifier: "10",
    variableName: "--header-background-color",
    defaultValue: "#fff",
  },
  {
    identifier: "11",
    variableName: "--header-background-color-text",
    defaultValue: "#fff",
  },
  { identifier: "1", variableName: "--theme-color", defaultValue: "#ff75a0" },
  { identifier: "3", variableName: "--button-color", defaultValue: "#ff75a0" },
  {
    identifier: "12",
    variableName: "--footer-background-color",
    defaultValue: "#2B3445",
  },
  {
    identifier: "13",
    variableName: "--footer-background-text-color",
    defaultValue: "#F8F9FA",
  },
  {
    identifier: "2",
    variableName: "--secondary-color",
    defaultValue: "#088178",
  },
  {
    identifier: "17",
    variableName: "--loader-color",
    defaultValue: "#ff75a0",
  },
  {
    identifier: "16",
    variableName: "--megamenu-background-text-color",
    defaultValue: "#000",
  },
  {
    identifier: "15",
    variableName: "--megamenu-background-color",
    defaultValue: "#fff",
  },
];

export const PageIdentifiers = {
  HomeAdsSlider: "1",
  Login: "2",
  CheckOut: "3",
  Profile: "4",
  MobileLogin: "5",
  POSMobileDashboard: "6",
  LockScreen: "7",
  CentralizedHomePageAds: "8",
  POSWebDashBoard: "9",
  AllProduct: "10",
  ContactUs: "11",
  GiftCard: "12",
};
export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
